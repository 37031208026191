var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",class:{
    'has-error': _vm.validation.$error,
    'has-value': _vm.hasValue,
    'has-focus': _vm.isFocused,
    'is-disabled': _vm.disabled,
    'theme-dark': _vm.dark
  }},[(_vm.label)?_c('label',{staticClass:"form-label",attrs:{"for":'input' + _vm._uid}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.isRequired && !_vm.disabled)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-input-wrapper"},[(_vm.mask)?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mascaraConfigurada),expression:"mascaraConfigurada"}],ref:"input",staticClass:"form-input",attrs:{"id":'input' + _vm._uid,"spellcheck":"false","type":_vm.internalType,"autocomplete":_vm.autocomplete,"autocapitalize":_vm.autocapitalize,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)},"focus":function($event){return _vm.updateFocus()},"blur":function($event){return _vm.updateBlur()}}}):_vm._e(),(!_vm.mask)?_c('input',{ref:"input",staticClass:"form-input",attrs:{"id":'input' + _vm._uid,"spellcheck":"false","autofocus":_vm.autofocus,"type":_vm.internalType,"autocomplete":_vm.autocomplete,"autocapitalize":_vm.autocapitalize,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)},"focus":function($event){return _vm.updateFocus()},"blur":function($event){return _vm.updateBlur()}}}):_vm._e(),_c('div',{ref:"append",staticClass:"form-input-append"},[(_vm.isPassword)?_c('action',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeVisiblity()}}},[_c('icon',{attrs:{"slot":"icon","name":_vm.visibilityIcon},slot:"icon"})],1):_vm._e()],1)]),_c('div',{staticClass:"form-input-details"},[_c('validation-message',{attrs:{"validation":_vm.validation}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }